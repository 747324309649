import * as React from 'react'

// Components
import PageContent from '../components/PageContent/PageContent'
import PreFooter from '../components/PreFooter/PreFooter'

const DelayDisruptionAnalysis = () => {
  return (
    <>
      <PageContent pageId={31} />
      <PreFooter />
    </>
  )
}

export default DelayDisruptionAnalysis
